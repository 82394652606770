<div class="d-flex justify-content-between align-items-center pb-2 no-before" mat-dialog-title>
  <h2 class="m-0">Tutor Virtuale</h2>
  <mat-icon fontSet="material-symbols-outlined" mat-dialog-close>close</mat-icon>
</div>

<div class="py-2" mat-dialog-content>
  <div><b>ALICE (Artificial Learning Intelligent Classroom Expert)</b> è il Tutor Virtuale di Futura.</div>
  <br />
  @if (!data || !data.type || data.type === 'question') {
    <span>
      Grazie alla sua tecnologia avanzata, è in grado di riformulare le spiegazioni in modo chiaro e semplice e di fornire approfondimenti sulle parti più
      complesse dei concetti, aiutando gli studenti a raggiungere i loro obiettivi di apprendimento.
    </span>
  }
  @if (data.type === 'not_runnable') {
    <span> Al momento Alice non può darti suggerimenti per le domande di comprensione del testo e/o che contengono immagini. </span>
  }
  @if (data.type === 'simulation_tip') {
    <span>
      Grazie alla sua tecnologia avanzata, è in grando di suggerirti il procedimento logico per raggiungere la soluzione.<br /><br />
      NON ti indicherà la risposta corretta.
    </span>
  }
</div>
