import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-alice-info-dialog',
  templateUrl: './alice-info-dialog.component.html',
  styleUrls: ['./alice-info-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatIcon, MatDialogClose, MatDialogContent],
})
export class AliceInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: undefined | 'not_runnable' | 'question' | 'simulation_tip';
    }
  ) {}
}
